<template>

  <div class="card form-rounded-inputs" v-if="productInformation.completed < 100 " >

    <div class="card-body">
      <h6>
        <span v-text="$t('dashboard.product.improvements')"> </span>
        <small :class=" (productInformation.completed == 100) ? 'text-success':'' ">
          <strong v-text="` (${productInformation.completed}%)`"> </strong>
        </small>
      </h6>
      <p>
         <small class="text-muted" v-text="$t('dashboard.product.improvementsMessage')"> </small>
      </p>
      <div class="row align-items-center mb-1">
        <div class="col-12 ">
          <div class="progress-showcase">
            <div class="progress" style="height: 8px;">
              <div class="progress-bar bg-success" role="progressbar"
                :style=" 'width:' +  productInformation.completed  + '%' " aria-valuemin="0" aria-valuemax="100">
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="mb-0 cursor-pointer" v-for=" (ptoductTip,index ) in  productInformation.tips " :key="index">
        <i class="fa fa-bullseye fa-sm mr-1 text-success"></i>
        <small v-text="$t('dashboard.dashboard.productDetails.' + ptoductTip )"
          @click="fnGlobalScrollTo('product-'+ ptoductTip )"></small>
      </p>
    </div>
    <ProductDiscount :productInfo="tempProduct" :showModalDiscount="showModalDiscount "
      @update-discount=" fnUpdateDiscount " />
  </div>
</template>

<script>

  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex';

  import ProductDiscount from '@/components/dashboard/product/product-discount-modal.vue';

  export default {
    components: {
      ProductDiscount,
    },
    computed: {
      ...mapState('EcartProduct', ['loading', 'productInformation', 'storeInformation' ]),
      ...mapState('productReviews', ['reviewsPagination']),
      ...mapState('productQuestions', ['questionsPagination']),
    },
    watch: {},
    data() {
      return ({
        showModalDiscount: false,
        tempProduct: {
          discounts: []
        },
        counts: {
          productReviews: 0,
          productQuestions: 0,
        }
      })
    },
    methods: {
      ...mapActions('EcartProduct', ['fnApiSyncProduct', 'fnApiSellOutOfStock']),
      ...mapMutations('EcartProduct', ['changeModal', 'updateProductVariantsDiscounts']),

      fnShowDiscount() {
        this.showModalDiscount = !this.showModalDiscount;
        this.tempProduct = {
          discounts: []
        };
        this.tempProduct = {
          ...this.productInformation
        };
      },
      fnUpdateDiscount(discount, variantList) {
        this.updateProductVariantsDiscounts({
          discount,
          variantList
        })
      }
    },
    mounted() {}
  }
</script>